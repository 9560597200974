/* implemeneted */
button.ant-btn-primary {
  background-color: #2b74c4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #2b74c4;
  border-radius: 5px;
  text-transform: uppercase;
}

button.ant-btn-primary:hover, button.ant-btn-primary:focus {
  color: #2b74c4;
  border-color: #2b74c4;
  background-color: #f4f5f7;
}
button.ant-btn-primary[disabled], 
button.ant-btn-primary[disabled]:hover {
  color: #fff9;
  border-color: #4a88cb;
  background-color: #4a88cb;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #fff;
}

.lang-dropdown .dropdown-toggle {
    display: inline-flex;
    gap: 5px;
    align-items: center;
 }

.lang-dropdown .dropdown-menu {
  min-width: 6.5rem !important;
}

#cookie-consent-button {
  height: auto !important;
  padding: 12px 40px !important;
}

html[class|='focus-outline-hidden'] body {
  padding-right: 0 !important;
}

.border-0 {
  border-width: 0 !important;
}

div[data-cky-tag|=detail-powered-by] {
  display: none !important;
}

button.cky-btn {
  border-radius: 10px;
}