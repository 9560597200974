@media (min-width: 421px) and (max-width: 1200px) {


  .marginCeap {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .comoRelaciona {
   margin-left: 32px !important;
   margin-right: 32px !important;
  }


.caruselInsideCeap {
  width: 590px !important;
  margin: auto !important;
}

  .iconCeap {
   margin: auto !important;
  }

.paddingCeap {
  padding-top: 20px !important;
}
.caruselCeap {
  width: 700px !important;
  margin: auto !important;
}

.butonBottomAdjust {
  position: absolute;
  left: 101% !important;
}
  .marginLeftHome {
    margin-left: 20px;
  }
  .marginRightHome {
    margin-right: 20px;
  }
  .imgRosco {
    width: 500px;
  }

  .marginGrid {
    margin-top: 15px;
  }
  .marginSubTitle {
    margin-top: 32px;
  }
  .marginSubText {
    margin-top: 32px;
  }
  .defaultFull {
    min-height: 100% !important;
    margin-top: 20px !important;
  }
  .defaultgrid {
    padding-left: 60px !important;
    padding-right: 40px !important;
  }
  .carousel-control-next {
    margin-right: 20px;
  }
  .footerLink {
    font-size: 14px !important;
  }
  .closeWindow {
    width: 44px;
  }
  .arrow-prev {
    width: 24px;
    height: 24px;
  }
  .menu-item-img {
    position: absolute;
    left: 26%;
}
  .arrow-next {
    width: 24px;
    height: 24px;
  }
  .menu-item-scroll {
    margin-right: 4px;
    width: 150px;
    height: 110px;
  }
  .estrella {
    width: 70px;
    height: 70px;
  }
  .imgCarrusel {
    height: 290px !important;
  }
  .txtHome {
    font-size: 0.875em !important;
  }
  .txtHomelink {
    font-size: 0.875em !important;
  }
  .txtHomeLittle {
    font-size: 0.813em;
  }
  .txtHomeClaves {
    font-size:0.813em;
  }
  .txtTestimonio {
    font-size: 0.75em !important;
  }
  .footerbg {
    height: 70px !important;
  }
  .navHeader {
    height: 100px;
  }
  .cornerHomeLeft {
    border-bottom-left-radius: 60px !important;
  }
  .cornerHomeRight {
    border-bottom-right-radius: 60px !important;
  }

  .homeContainer {
    padding: 30px !important;
  }
  .homeCarrusel {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .btnRemove {
    height: 50px !important;
    width: 333px !important;
  }
  .btnHome {
    height: 50px !important;
    width: 333px !important;
  }
  .btnDisabled {
    height: 50px !important;
    width: 333px !important;
  }
  .btnFilter {
    width: 160px !important;
  }
  .btnMenu {
    width: 220px !important;
    font-size: 13px !important;
    padding-top: 15px !important;
  }
  .btnBoard {
    width: 220px !important;
    font-size: 13px !important;
    padding-top: 12px !important;
  }
  .widthContact {
    width: 175px !important;
  }
  .imgHomeVideo {
   width: 333px;
   height: 333px;
  }
  
  .imgHome {
    width: 333px;
  }

  .videoCeapHome {
    margin: auto !important;
    width: 320px !important;
    height: 180px !important;
  }
  .imgHomeXcelence {
    width: 300px;
  }
  .itemCaruselCeap {
    width: 300px !important;
    margin: auto !important;
  }
  

  .imgCaruselCeap{
    width: 146px !important;
  } 
  .imgHomeComo {
    width: 320px !important;
  }
  .h2home {
    font-size: 1.333em !important;
  }
  .h1home {
    font-size: 1.75em !important;
  }

  .lineCeap {
    line-height: 32px !important;
    font-size: 19px !important;
  }
  .h1link {
    font-size: 1.75em !important;
  }
  .subh1home {
    font-size: 1.125em !important;
  }
  .titleToolkit {
    font-size: 1.75em !important;
  }
  .subtoolkit {
    font-size: 1.125em !important;
  }
  .toolkitPregunta {
    font-size: 1.125em !important;
  }
  .logoHeader {
    margin-left: 20px;
    padding-top: 20px;
  }
  .logoHeader img {
    width: 175px;
  }
  .navHeader {
    height: 100px;
    min-height: 50px;
    display: block;
    position: relative;
  }
  ul.collapsed {
    top: 70px !important;
  }
  .privacityp {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .textPrivacity {
    font-size: 0.889em;
    font-weight: 500;
    line-height: 1.33;
  }
  .nofull {
    width: 180px !important;
  }
  .registerWidth {
    height: 50px !important;
    width: 370px !important;
  }
  .labelForm {
    font-size: 0.889em;
  }
  .widthF {
    width: 320px !important;
  }
  .registerWidth {
    width: 320px !important;
  }
  .widthContactForm {
    width: 360px !important;
  }
  .btnFullButton {
    width: 330px !important;
  }

  .sNoResponsive {
    display: none;
  }

  .sResponsive {
    display: inline-block;
  }
  .rounded {
    border-radius: 20px !important;
  }
  .carruselClaves {
    width: 560px !important;
    margin-left: 60px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .butonBottomAdjust {
    position: absolute;
    left: 101% !important;
  }
  .marginLeftHome {
    margin-left: 10px;
  }
  .marginRightHome {
    margin-right: 0px;
  }
  .imgMapaRes {
    width: 500;
    height: 400;
  }
  .titlePracticas {
    font-size: 0.889em !important;
  }
  .descToolkit {
  
    padding-left: 10px !important;
}
  .paddingBotones {
    margin-left: -18px !important;
  }
}
@media only screen and (max-width: 900px) {
  .butonBottomAdjust {
    position: absolute;
    left: 101% !important;
  }
  .bocadillo-cuadrado { 
    margin-right: 5px;
    padding-top: 35px;
    position: relative;
    height: 300px;
    width: 350px;
  }
  .imgTestimonio {
    width: 90px !important;
  }
  .tabSelect {
    width: 150px !important;
  }
  .tabTextTitle {
    font-size: 10px !important;
  }
  .MuiTab-root {
    font-family: "MuseoSans", sans-serif !important;
    color: #85888c !important;
    font-size: 0.688em !important;
    min-width: 90px !important;
  }
  .MuiTabs-indicator {
    width: 90px !important;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .carruselClaves {
    width: calc(100% - 120px) !important;
    margin-left: 60px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .butonBottomAdjust {
    position: absolute;
    left: 101% !important;
  }
  .carruselClaves {
    width: calc(100% - 120px) !important;
    margin-left: 60px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .butonBottomAdjust {
    position: absolute;
    left: 130% !important;
  }

}

@media only screen and (max-width: 1200px) {
  .butonBottomAdjust {
    position: absolute;
    left: 101% !important;
  }

  .imgDownload {
    width: 750px !important;
  }
  .paddingRecursos {
    padding-right: 60px;
    padding-left: 60px;
  }
  .menuResponsiveInv {
    display: none;
  }
  .menuResponsive {
    display: inline-block;
  }
  .widthSimple {
    width: 220px !important;
  }

  .simpleGrid {
    padding-left: 80px !important;
  }
  .gridnoPadding {
    padding-left: 20px !important;
  }
  .MuiGrid-spacing-xs-3 {
    margin: -12px;
  }

  .menu-item-scroll-title {
    font-size: 0.75em;
  }
  .menu-item-scroll-name {
    font-size: 14px;
  }

  ol.carousel-indicators li,
  ol.carousel-indicators li.active {
    padding: 0px !important;
  }
  .buttonNewsletter {
    padding-top: 9px;
    width: 187px !important;
    height: 35px !important;
  }
  .homeContainerLogo {
    height: 160px !important;
  }
  .homeContainerNewsletter {
    height: 180px !important;
  padding-top: 10px !important;
  }
  .logoBertelsHome {
    height: 45px;
    margin-top: 15px !important;
  }

  .closeWindow {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .fa-bars {
    display: inline-block;
    position: absolute;
    top: 35px;
    right: 20px;
    cursor: pointer;
  }
  .menuDrawer {
    display: inline-block;
    position: absolute;
    top: 35px;
    right: 50px;
    cursor: pointer;
  }

  ul.collapsed {
    background: var(--white-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    top: 50px;
    right: 0px;
    overflow: hidden;
    max-height: 0;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }
  ul.is-expanded {
    z-index: 1000;
    overflow: hidden;
    max-height: 720px; /* approximate max height */
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  li.menuPrincipal {
    list-style: none;
    background: url(./azul.svg) no-repeat center right;
    padding-right: 10px;

    padding: 15px 10px;
    margin: 0px 0px;
    width: 100%;
    background-color: var(--white-color);
    border-bottom: solid 1px var(--grey-color) !important;
    height: 59px;
  }

  .menugreybk {
    background-color: #fafbfc !important;
  }
  .carousel-indicators {
    z-index: 1 !important;
  }
  li.menuPrincipal .whiteLink {
    color: var(--home-color) !important;
    font-size: 0.889em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  li.menuPrincipal .whiteLink .active {
    text-decoration: underline;
    text-underline-position: under;
  }
  .sepMenu {
    background-color: var(--white-color);
    border-bottom: solid 1px var(--grey-color) !important;
    height: 129px !important;
  }

  .btnRegistroMenu {
    height: 80px !important;
  }

  .claveIndicadores li {
    padding: 4px !important;
  }
  .claveIndicadores li.active {
    padding: 4px !important;
  }

  .marginLeftNav {
    margin-left: 0px;
  }
  .marginLeftNavResponsive {
    margin-left: 25px;
  }
  .more {
    margin-left: 0px !important;
  }
  .Oval {
    width: 102px;
    height: 102px;
    padding-top: 28px;
    border-radius: 50px;
    box-shadow: 0 2px 0 0 rgba(127, 136, 151, 0.19);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.56px;
    color: #ffffff;
    text-transform: uppercase;
  }
  .claveNum {
    font-size: 2.375em !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1 !important;
    letter-spacing: normal !important;
    color: #ffffff !important;
  }

  .modalViewVideo {
    z-index: 0;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -322px;

    margin-top: -300px;
    width: 640px;
    height: 360px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    border: solid 1px var(--grey-color);
    background-color: var(--grey-color);
  }

  .modalvideo {
    height: 84%;
  }

  .underVideo {
    position: absolute;
    left: 50%;
    top: 60%;
    margin-left: -180px;
    margin-top: 10px;
  }

  body {
    position: relative;
    overflow-x: hide !important;
    -ms-overflow-style: scroll !important;
  }

  .imgListadoPracticas {
    width: 180px !important;
  }

  .posDownload {
    float: right !important;
    margin-right: 50px !important;
    margin-top: 0px !important;
  }
  .tabPadding .MuiBox-root {
    padding-left: 1px !important;
  }

  .showallccaa {
    right: 15px !important;
  }
}
