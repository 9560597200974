@media
only screen and (-webkit-min-device-pixel-ratio: 1),
only screen and (   min--moz-device-pixel-ratio: 1),
only screen and (     -o-min-device-pixel-ratio: 1/1),
only screen and (        min-device-pixel-ratio: 1) { 

	.marginerror {
		margin-right: 15px;
	  }
	  .errorLayer {
		margin-top: 3px;
		font-size: 12px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 0.2px;
		color: var(--error-color);
	  }
	  
	  .selectFilters{
		
	  }
	  .lateralFiltros {
	
	  }
}