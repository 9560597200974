.pospending {
  position: absolute;
  bottom: 45px !important;
  left: 0;
  width: 100%;
  border-top: 1px solid #dfe1e5;
  padding-top: 5px;
  padding-left: 2px;
  margin-right: 2px;
}

.listdonespending {
  display: flex;
}

.itemposRecurso {
  height: 4px !important;
  border-radius: 5px !important;
  margin-bottom: 8px !important;
  flex-basis: 100%;
  margin-right: 5px;
}

.contentRecurso {
  height: 281px !important;
}

.cardRecurso.best_practice .ant-card-body {
  background-color: #2b74c433;
}
.cardRecurso .imgTipoRecurso {
  width: 100%;
  height: auto;
}
.centerAlign {
  text-align: center;
}

.cardRecurso {
  border-top: 8px solid #dc0740 !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  width: 256px !important;
  height: 417px;
  margin: 33px 33.2px 30px 31px;
  padding: 0 0 15px;
  box-shadow: 0 2px 4px 0 rgba(94, 104, 113, 0.07);
  background-color: #FFFFFF !important;
  margin-top: 28px !important;
}

.imgTipoRecurso {
  width: 60px;
  height: 60px;
}
.ant-card-actions {

  border-bottom: 1px solid #f0f0f0 !important;
  border-bottom-right-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

.tipoRecurso.cover {
  width: 80px;
  height: 80px;
  border-radius: 40%;
  overflow: hidden;
  padding: 12px;
  background-color: #2b74c4;
}
.tipoRecurso {
  margin: 8px auto 16px;
  font-family: MuseoSans;
  font-size: 12px;
  color: #8994a9;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
}
.tituloRecurso {
  margin: 0;
  font-family: MuseoSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
}

.descRecurso {
  font-family: MuseoSans;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
}
.subtipoRecurso {
  text-align: center !important;
  font-family: MuseoSans;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
  margin-bottom: 3px;
}
.actRecurso {
  margin-top: 5px;
  font-family: MuseoSans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
  text-align: center;
}

.readMore {
  margin-top: 10px;
  text-decoration: underline;
  font-family: MuseoSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #2b74c4;
}

.readMore:hover {
  color: #507092;
}
.nameFilter {
  margin-top: 20px;
  font-family: MuseoSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
}
.listFilters {
  width: 255px !important;
  flex-grow: 0;
  margin: 8px 0 24px 1px;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 32px;
  border-radius: 4px;
  background-color: #f4f5f7;
  font-family: MuseoSans !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #383b41 !important;
}

/*
.ant-pagination-item {
  display: none !important;
}
.ant-pagination-item-ellipsis {
  display: none !important;
}
.ant-pagination-options-size-changer {
  display: none !important;
}
*/

.modalRecurso {
  padding-top: 40px;
  width: 100%;
  background-color: var(--grey-color) !important;
}
.insideRecursos {
  margin: auto;
  display: flex;
  width: 1057px !important;
  background-color: var(--white-color) !important;
}

.lateralRecurso {
  flex: 297px !important;
  margin: 0 0px 0 0;
  padding: 32px 32px 144px;
  background-color: #26588e;
}
.showRecurso {
  flex: 760px !important;
  margin: 20px 0 60px;
  padding: 0 40px 0 40px;
  background-color: #ffffff;
}

.lateralTipo {
  font-family: MuseoSans;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-top: 20px;
}
.infoTipo {
  font-family: MuseoSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-top: 4px;
}

.infoTipo a {
  font-family: MuseoSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-top: 4px;
}
.infoTipo a:hover {
  text-decoration: underline !important;
  font-family: MuseoSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-top: 4px;
}

.modalTituloRecurso {
  font-family: MuseoSansRounded;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
}
.imgTipo {
  width: 72px !important;
  height: 72px !important;
  margin-right: 24px;
  float: left;
}

.modalClavesRecurso {
  margin-top: 25px;
  font-family: MuseoSans;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
}

.modalNomClaveRecurso {
  font-family: MuseoSans;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #383b41;
}

.modalDescRecurso {
  margin-top: 25px;
  font-family: MuseoSansRounded !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #383b41 !important;
}

.queClaseEs {
  margin-top: 8px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
  border: solid 1px #2b74c4;
  background-color: #ffffff;
}

.relacionados {
  font-family: MuseoSans;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2f30;
}
.showRels {
  text-align: center !important;
  width: 1057px !important;
  margin: auto !important;
  margin-top: 50px !important;
}

.imgDestacado {
  position: absolute;

  left: -2px;
  top: -11px;
}

.imgDestacado img {
  width: 70px;
  height: 60px;
}
.forDesktop {
  display: inline-block !important;
}
.forMobile {
  display: none !important;
}

.inFilters {
  display: none !important;
}

.rowFil {
  width: 100%;
  display: flex;
}

.columnFil {
  flex: 50%;
  margin-right: 15px !important;
}
.ant-pagination {
  display: none !important;
}

.blur {
  -webkit-filter: blur(0px) !important;
  -moz-filter: blur(0px) !important;
  -o-filter: blur(0px) !important;
  -ms-filter: blur(0px) !important;
  filter: blur(0px) !important;
  background-color: #fff !important;
}
.ant-input-suffix {
  color: #2b74c4 !important;
}

.lateralRecursoCollapse {
  background-color: #26588e !important;
  color: #fff !important;
  display: none !important;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.tituloFichaT {
  font-family: MuseoSansRounded;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.anticon-right .ant-collapse-arrow {
  color: #fff !important;
  font-family: MuseoSans !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #26588e !important;
  border-top: 0px solid #26588e !important;
}

.ant-collapse-header {
  color: #fff !important;
  font-family: MuseoSans !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.ant-pagination-options {
  display: none !important;
}
.marginRecurso {
  margin-top: 60px;
  margin-left: 100px;
  margin-right: 100px;
}
.customMt2 {
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #383b41 !important;

  border-bottom: 1px solid #dfe1e5;
}

.btnFilterRecursos {
  height: 48px !important;
  flex-grow: 0 !important;
  margin: 23px 40.2px 8px 13px !important;
  padding: 12px 25px !important;
  border-radius: 44px !important;
  border: solid 1px #2b74c4 !important;
  background-color: #ffffff !important;
  font-family: MuseoSans !important;
  font-size: 13px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #2b74c4 !important;
}

.btnFilterRecursos:hover {
  background-color: var(--blue-color) !important;
  color: var(--white-color) !important;
}

.btnAccede {
  height: 48px !important;
  flex-grow: 0 !important;
  margin: 12px 40.2px 8px 13px !important;
  padding: 12px 25px !important;
  border-radius: 44px !important;
  border: solid 1px #2b74c4 !important;
  background-color: var(--blue-color) !important;
  font-family: MuseoSans !important;
  font-size: 13px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #ffffff !important;
}

.btnAccede:hover {
  background-color: var(--white-color) !important;
  color: var(--blue-color) !important;
}

.linkaRecurso {
  font-family: MuseoSans !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #2b74c4 !important;
}

.linkaRecurso:hover {
  font-family: MuseoSans !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #29425e !important;
}
.linkaRecurso:active {
  font-family: MuseoSans !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #2b74c4 !important;
}

.linkaRecurso:visited {
  font-family: MuseoSans !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #2b74c4 !important;
}

.totalRecursos {
  margin-top: -20px !important;
  font-family: MuseoSans;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #2d2f30;
}
.imagenLateralRecursoImg {
  max-width: 240px!important;
}
.imagenLateralRecurso {
  text-align: center !important;
}

.imagenLateralRecurso.logo {
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
}

.ant-select-selection__placeholder{
  color : blue;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

.imgPcRecurso {
    width: 225px !important;
}

.homeContainerRecursos {
  padding-bottom: 0px !important;
  padding-top: 20px !important;
}
@media (min-width: 421px) and (max-width: 1200px){
.homeContainerRecursos {
    padding: 20px !important;
}
}

.marginSubTitleRecursos {
  margin-bottom: 33px !important;
}

.ant-input-affix-wrapper {
  border: 1px solid #f4f5f7 !important;
  background-color:#f4f5f7 !important;
}

.ant-input-affix-wrapper-focused {
  border: 1px solid #f4f5f7 !important;
  background-color:#f4f5f7 !important;
  box-shadow: 0 0 0 0px !important;
}
.ant-input {
  background-color:#f4f5f7 !important;
  border-color: 1px solid #f4f5f7 !important;
  color: #383b41 !important
}
.ant-input:focus {
  border: 1px solid #f4f5f7 !important;
  background-color:#f4f5f7 !important;
  box-shadow: 0 !important;
  color: #383b41 !important
}
/* .ant-btn-primary {
  color: #fff !important;
  background: #f4f5f7 !important;
  border-color: #f4f5f7 !important;
  text-shadow: 0 0px 0 rgb(0 0 0 / 12%) !important;
  box-shadow: 0 0px 0 rgb(0 0 0 / 5%) !important;
} */
.ant-input-search-button {
  color: #2b74c4 !important;
  background: #f4f5f7 !important;
  border-color: 1px solid #f4f5f7 !important;
}
.h2recursos {
  font-family: MuseoSansRounded;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2f30;
}
.marginRecursosB {
  margin-top: 10px !important;
}
@media (max-width: 1200px) {
  .h2recursos {
    margin-bottom: 30px !important;
  }
  .imgPcRecurso {
    width: 250px !important
}
  .center {
    margin-left: -35px !important;
  }
.cardRecurso{
  width: 335px !important;
  height: 415px !important;
}
  .btnVolver {
    margin-top: 40px !important;
  }
  .queClaseEs {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .imgTipo {
    margin-right: 16px !important;
    width: 40px !important;
    height: 40px !important;
    float: left;
  }
  .insideRecursos {
    width: 100% !important;
  }

  .lateralRecurso {
    flex: 297px !important;
    margin: 0 0px 0 0;
    padding: 32px 32px 144px;
    background-color: #26588e;
  }
  .showRecurso {
    flex: 95% !important;
  }

  .showRels {
    width: 95% !important;
  }
  .blur {
    -webkit-filter: blur(5px) !important;
    -moz-filter: blur(5px) !important;
    -o-filter: blur(5px) !important;
    -ms-filter: blur(5px) !important;
    filter: blur(5px) !important;
    background-color: #fff !important;
  }
  .hideElement {
    display: none !important;
  }
  .showElement {
    display: inline-block !important;
  }
  .mobileW {
    width: 100% !important;
  }
  .forMobile {
    display: inline-block !important;
  }
  .forDesktop {
    display: none !important;
  }

  .inFilters {
    display: inline-block !important;
  }
  .filtrosTablet {
    position: absolute !important;
    top: 90px !important;
    left: 0px !important;
    cursor: pointer !important;
    background-color: #ffffff !important;
    z-index: 1 !important;
    flex-grow: 0 !important;
    padding: 11.7px 7.1px 30px 17.9px !important;
    border-radius: 4px !important;
    box-shadow: 0 4px 16px 0 rgba(94, 104, 113, 0.16) !important;
    background-color: #ffffff !important;
  }
  .iconsclose-verde {
    float: right !important;
    color: #2b74c4 !important;
    margin-top: 10px !important;
    cursor: pointer;
  }

  .listFilters {
    background-color: #ffffff !important;
  }

  .modalRecurso {
    padding: 0px !important;
    background-color: var(--grey-color) !important;
  }
  .marginRecurso {
    margin-top: 60px !important;
    margin-left: 30px !important;
    margin-right: 0px !important;
  }
  .relacionados {margin-bottom: 32px !important;}
}
@media (max-width: 415px) {
  .marginSubTitleRecursos {margin-left: -30px !important;}
  .marginRecursosB {
    margin-top: 0px !important;
  }
  .h2recursos {
    font-size: 20px !important;
    margin-top: -30px !important;
  }
  .imgPcRecurso {
    margin-top: -45px !important;
    width: 168px !important
}
  .center {
    margin-left: -30px !important;
  }
  .tituloFichaT {
    text-align: center !important;
    height: 45px !important;
    padding-top: 6px !important
  }
  .imagenLateralRecursoImg {
    float: left;
    margin-right: 0px !important;
  }
  .cardRecurso{
    width: 299px !important;
  }
  .modalRecurso {
    padding: 0px !important;
    background-color: var(--grey-color) !important;
  }
  .showRels {
    padding-left: 4px !important;
    padding-right: 40px !important;
    margin: 30px auto auto !important;
  }

  .relacionados {
    font-family: MuseoSans !important;
    font-size: 20px !important;
    margin-top: 32px !important;
    margin-bottom: 24px !important;
    margin-left: 20px !important;
  }
  .lateralRecurso {
    display: none !important;
  }
  .lateralRecursoCollapse {
    display: block !important;
  }
  .showRecurso {
    flex: 100% !important;
  }
  .marginSubTitle {margin-left: -10px !important;}
}

@media (max-width: 500px) {

  .totalRecursos {
    margin-top: 10px !important;
    text-align: left !important;
    margin-bottom: 24px !important;
  }

  .relacionados {margin-bottom: 22px !important;}
  .btnVolver {
    margin-top: 25px !important;
    width: 90% !important;
  }
  .lateralTipo {
    font-size: 12px !important;
  }
  .btnAccede {
    font-size: 12px !important;
  }
  .showRels {
    width: 100% !important;
  }
  .queClaseEs {
    margin-top: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .modalTituloRecurso {
    font-family: MuseoSansRounded;
    font-size: 20px !important;
  }
  .imgTipo {
    margin-right: 8px !important;
    width: 40px !important;
    height: 40px !important;
    float: left;
  }
  .insideRecursos {
    width: 100% !important;
  }

  .lateralRecurso {
    flex: 297px !important;
    margin: 0 0px 0 0;
    padding: 32px 32px 144px;
    background-color: #26588e;
  }
  .showRecurso {
    flex: 95% !important;
  }

  .rowFil {
    flex-direction: column;
  }
  .columnFil {
    margin-bottom: 15px !important;
  }
  .filtrosTablet {
    top: 66px !important;
  }
  .modalRecurso {
    padding: 0px !important;
    background-color: var(--grey-color) !important;
  }
  .marginRecurso {
    margin-top: 20px !important;
    margin-left: 20px !important;
    margin-right: 0px !important;
  }
}

.site-badge-clave {
  width: 30 !important;
  height: 30px !important;
  border-radius: 90px !important;
}