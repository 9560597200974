.headerClave{
  height: 60px;
  font-family: "MuseoSans";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  text-align:center;
}

.gridContainerContestar{
  width:769px;

}

.gridTituloContestar{
  margin-top:65px;
}

.inputCampoAbierto{
  width:200px !important;
  height:40px !important;
  margin-top:10px !important;
  margin-left: 15px !important;
}

.tituloClaveContestar{
  margin-top:65px;
  font-family: "MuseoSans";
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2d2f30;
}

.subtituloContestar{
  margin-top:35px;
  width:100%;
  font-family: "MuseoSans";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2d2f30;
}

.subtituloContestar b {
  font-weight: bold;
}

.formControlCampo{
  color:"black"
}


.botonesAccion{
  bottom: 17;
  position: fixed;
  z-index: 300;
}

.gridBotones{
  
}


.gridBotonesAdvertencia{
  margin-top:7% !important; 

}
.gridBotonesTabla{
  margin-top:25px !important; 

}

.buttonContestarContinuar{
  text-align: center;
  height: 56px !important;
  width: 252px !important;
  padding-top: 10px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.buttonContestarMasTarde{
  text-align: center;
  height: 56px !important;
  width: 252px !important;
  padding-top: 10px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  
}


.buttonContestarEnviar{
  text-align: center;
  height: 50px !important;
  width: 194px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.buttonContestarRevisar{
  text-align: center;
  height: 50px !important;
  width: 194px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  
}
.buttonContestarFooterDescargar{

  text-align: center;
  height: 56px !important;
  width: 252px !important;
  padding-top: 10px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  
  
}

.buttonLessMore {
  width:40px !important;
  height:40px !important;
  text-align: center;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  padding: 0px !important;
  

}


.botonesAccionModalAdvertencia{
  margin-left: 8% !important;
}

.topeWidth{
  width: 100% !important;
  
}

.footerbgContestar {
  height: 88px !important;
  background-color: #f4f5f7 !important;
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: 100;
}

.gridPreguntas{
  overflow: auto;
  padding-bottom: 118px;
  font-family: "MuseoSans";
  font-weight: 700;
}


.escalaNota{
  height: 46px;
  text-align:center;
  vertical-align: middle;
  padding-top: 10px;
  cursor:pointer;
}

.containerEscala{
  width: 533px;
  border-radius: 8px;
 
}

.lateralBar{
  position: absolute;
  right: 10%;
  top: 320;
  width: 30px;
}

.activeLateralBar{
  font-size:1.0rem !important;
}

.circleLateralBar{
  font-size:0.7rem !important;
  color: #c4c4c4 !important; 
  cursor: pointer;

}

.tickLateralBar{
  font-size:1.2rem !important;
  cursor: pointer;


}
  @media only screen and (max-width: 1280px) {
    .containerEscala{
      width: 600px !important;
      border-radius: 8px;
     
    }
    .headerClave{
      height: 60px;
      font-family: "MuseoSans";
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #ffffff;
      text-align:left;
      padding-left:5%;
    }
    .GridContestarReponsive{
      margin-left:50px !important;
    }

    .buttonLessMore {
      width:40px !important;
      height:40px !important;
      text-align: center;
      text-transform: uppercase !important;
      border-radius: 4px !important;
      font-size: 14px !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1 !important;
      letter-spacing: 0.5px !important;
      text-align: center !important;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
      padding: 0px !important;
      
    
    }

    .buttonContestarFooterDescargar{
      text-align: center;
      height: 56px !important;
      width: 220px !important;
      padding-top: 10px !important;
      text-transform: uppercase !important;
      border-radius: 4px !important;
      font-size: 14px !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1 !important;
      letter-spacing: 0.5px !important;
      text-align: center !important;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
      
      
    }
  
    .HideResponsive{
      display:none;
    }
  }



  @media only screen and (max-width: 595px) {
   

  }

  @media only screen and (max-width: 420px) {




  }
  
  