.ovalized{
  width: 136px;
  height: 136px;
  border-radius: 70px;
  padding-top: 20px;
  box-shadow: 0 2px 0 0 rgba(127, 136, 151, 0.19);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 4% !important;

}


  .claveNumberText{
   
    height: 48px;
    font-family: "MuseoSans";
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
  }

  .Clave {

    height: 18px;
    font-family: "MuseoSans";
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2px;
    color: #ffffff;
  }

  .tituloClave{
    text-align: center;
    font-family: "MuseoSans";
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #2d2f30;
    width: 769px !important;
    margin-bottom: 25px !important;
  }

  .gridTitulo {
    margin-top: 2% !important;
  }

  .gridDefinicion {
    margin-top: 1% !important;
  }

  .definicion{
    width: 769px;
  
    font-family: "MuseoSans";
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #383b41;
  }

  .buttonIntro{
    text-align: center;
    height: 50px !important;
    width: 450px !important;
    padding-top: 15px !important;
    text-transform: uppercase !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
    font-size: 14px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1 !important;
    letter-spacing: 0.5px !important;
    text-align: center !important;
    color: var(--white-color) !important;
  }

  .buttonIntro:hover{
    background:linear-gradient(
      rgba(0, 0, 0, 0.2), 
      rgba(0, 0, 0, 0.2))
    
  }


  @media only screen and (max-width: 768px) {

    .definicion{
      width: 620px;
     
      font-family: "MuseoSans";
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #383b41;
      text-align:justify;
    }

  }

  @media only screen and (max-width: 595px) {
    .definicion{
      text-align:justify;
      width: 500px;
     
      font-family: "MuseoSans";
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #383b41;
    }
    .ovalized{
      width: 100px;
      height: 100px;
      border-radius: 70px;
      padding-top: 20px;
      box-shadow: 0 2px 0 0 rgba(127, 136, 151, 0.19);
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 2px;
      color: #ffffff;
      text-transform: uppercase;
      margin-top: 4% !important;
    
    }

    
  .claveNumberText{
    padding:0px;
    font-family: "MuseoSans";
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
  }

  .Clave {


    font-family: "MuseoSans";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    color: #ffffff;
  }

  .tituloClave{
    height: 26px;
    font-family: "MuseoSans";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #2d2f30;
  }

  .definicion{
    width: 500px;
   
    font-family: "MuseoSans";
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #383b41;
  }

  .buttonIntro{
    text-align: center;
    height: 50px !important;
    width: 400px !important;
    padding-top: 15px !important;
    text-transform: uppercase !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
    font-size: 14px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1 !important;
    letter-spacing: 0.5px !important;
    text-align: center !important;
    color: var(--white-color) !important;
  }

  .buttonIntro:hover{
    background:linear-gradient(
      rgba(0, 0, 0, 0.2), 
      rgba(0, 0, 0, 0.2))
    
  }

  }

  @media only screen and (max-width: 420px) {



    .ovalized{
      width: 100px;
      height: 100px;
      border-radius: 70px;
      padding-top: 20px;
      box-shadow: 0 2px 0 0 rgba(127, 136, 151, 0.19);
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 2px;
      color: #ffffff;
      text-transform: uppercase;
      margin-top: 4% !important;
    
    }

    
  .claveNumberText{
    padding:0px;
    font-family: "MuseoSans";
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
  }

  .Clave {


    font-family: "MuseoSans";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    color: #ffffff;
  }
  .tituloClave{
    height: 26px;
    font-family: "MuseoSans";
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #2d2f30;
  }
  .definicion{
    text-align:justify;
    width: 250px;
   
    font-family: "MuseoSans";
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #383b41;
  }



  .buttonIntro{
    text-align: center;
    height: 50px !important;
    width: 200px !important;
    padding-top: 15px !important;
    text-transform: uppercase !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
    font-size: 14px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1 !important;
    letter-spacing: 0.5px !important;
    text-align: center !important;
    color: var(--white-color) !important;
  }

  .buttonIntro:hover{
    background:linear-gradient(
      rgba(0, 0, 0, 0.2), 
      rgba(0, 0, 0, 0.2))
    
  }

  .gridDefinicionResponsive {
    margin-top: 10% !important;
  }


  }
  
  