@font-face {
  font-family: "Museo Sans";
  src: url("//db.onlinewebfonts.com/t/62c0135163427c652ae397f327f85010.eot");
  src: url("//db.onlinewebfonts.com/t/62c0135163427c652ae397f327f85010.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/62c0135163427c652ae397f327f85010.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/62c0135163427c652ae397f327f85010.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/62c0135163427c652ae397f327f85010.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/62c0135163427c652ae397f327f85010.svg#Museo Sans")
      format("svg");
}

:root {
  --clave1-bk: #3e7abd;
  --clave1-rs: #4587c5;
  --clave1-letter: #0088c8;
  --clave2-bk: #3e7abd;
  --clave2-rs: #4587c5;
  --clave2-letter: #0088c8;

  --clave3-bk: #60b471;
  --clave3-rs: #60b471;
  --clave3-letter: #00b56d;

  --clave4-bk: #75bb80;
  --clave4-rs: #75bb80;
  --clave4-letter: #40bc7e;

  --clave5-bk: #75bb80;
  --clave5-rs: #88c493;
  --clave5-letter: #64c592;

  --clave6-bk: #cca8ce;
  --clave6-rs: #986ca8;
  --clave6-letter: #a76cab;

  --clave7-bk: #db4b59;
  --clave7-rs: #db4b59;
  --clave7-letter: #db4b59;

  --clave8-bk: #df6868;
  --clave8-rs: #df6868;
  --clave8-letter: #fd6868;

  --clave9-bk: #e38077;
  --clave9-rs: #fe8177;
  --clave9-letter: #fe8177;

  --clave10-bk: #eb7f39;
  --clave10-rs: #d27e40;
  --clave10-letter: #eb7f39;
}

.sliceWrapper {
  position: absolute;
  top: 50px;
  left: 50px;
  padding: 5px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
}



.sliceWrapperClave {
 
  position: absolute;
 
  border-radius: 50%;
  padding: 5px;
  list-style: none;
  overflow: hidden;
}

.slice {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
}

.sliceinside {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
}

.centerWrapper li {
  overflow: hidden;
  position: absolute;
  top: -50%;
  right: -50%;
  width: 99%;
  height: 99%;
  transform-origin: -0% 100%;
}

.centerWrapper li:first-child {
  transform: rotate(0deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(2) {
  transform: rotate(36deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(3) {
  transform: rotate(72deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(4) {
  transform: rotate(108deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(5) {
  transform: rotate(144deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(6) {
  transform: rotate(180deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(7) {
  transform: rotate(216deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(8) {
  transform: rotate(252deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(9) {
  transform: rotate(288deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:nth-child(10) {
  transform: rotate(324deg) skewY(126deg);
  padding: 5;
}

.centerWrapper li:first-child .slice {
  background: white;
}
.centerWrapper li:first-child .slice2 {
  background: white;
}

.centerWrapper li:nth-child(2) .slice {
  background: white;
}

.centerWrapper li:nth-child(3) .slice {
  background: white;
}

.centerWrapper li:nth-child(4) .slice {
  background: white;
}

.centerWrapper li:nth-child(5) .slice {
  background: white;
}

.centerWrapper li:nth-child(6) .slice {
  background: white;
}

.centerWrapper li:nth-child(7) .slice {
  background: white;
}

.centerWrapper li:nth-child(8) .slice {
  background: white;
}

.centerWrapper li:nth-child(9) .slice {
  background: white;
}

.centerWrapper li:nth-child(10) .slice {
  background: white;
}

.centerWrapper {
  position: absolute;
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
}


#center {
  position: absolute;
  left: 162px;
  top: 162px;
  width: 176px;
  height: 176px;
  z-index: 10;
  background: #FFFFFF;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

#centermedia {
  position: absolute;
  left: 186px;
  top: 186px;
  width: 216px;
  height: 216px;
  z-index: 10;
  background: transparent;
  border: 2px dotted black;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.liborder {
 border: 2px solid #f4f5f7;
}




.sliceclave1 {
  opacity: 0.6;
  background-color: red !important;
  border: 6px dotted black;
}


.slicecl1 {
  opacity: 0.6;
  background-color: var(--clave1-bk) !important;
}

.slicecl1media {
  background: rgba(0, 0, 0, 0.3) !important
}


.slicecl2 {
  opacity: 0.6;
  background-color: var(--clave2-bk) !important;
}
.slicecl3 {
  opacity: 0.6;
  background-color: var(--clave3-bk) !important;
}
.slicecl4 {
  opacity: 0.6;
  background-color: var(--clave4-bk) !important;
}
.slicecl5 {
  opacity: 0.6;
  background-color: var(--clave5-bk) !important;
}
.slicecl6 {
  opacity: 0.6;
  background-color: var(--clave6-bk) !important;
}
.slicecl7 {
  opacity: 0.6;
  background-color: var(--clave7-bk) !important;
}
.slicecl8 {
  opacity: 0.6;
  background-color: var(--clave8-bk) !important;
}
.slicecl9 {
  opacity: 0.6;
  background-color: var(--clave9-bk) !important;
}
.slicecl10 {
  opacity: 0.6;
  background-color: var(--clave10-bk) !important;
}

.elipse {
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}
.valor {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    position: absolute;
  }

.elipseclave1 {
  background-color: var(--clave1-rs);
  left: 258px;
  top: 156px;
  z-index: 100;
}

.valorclave1 {
  color: var(--clave1-letter);
  left: 311px;
  top: 20px;
  z-index: 100;
}


.elipseclave2 {
    background-color: var(--clave2-rs);
    left: 298px;
    top: 177px;
    z-index: 100;
  }
  
  .valorclave2 {
    color: var(--clave2-letter);
    left: 413px;
    top: 95px;
    z-index: 100;
  }


.elipseclave3 {
    background-color: var(--clave3-rs);
    left: 317px;
    top: 228px;
    z-index: 100;
  }
  
  .valorclave3 {
    color: var(--clave3-letter);
    left: 457px;
    top: 228px;
    z-index: 100;
  }


.elipseclave4 {
    background-color: var(--clave4-rs);
    left: 302px;
    top: 282px;
    z-index: 100;
  }
  
  .valorclave4 {
    color: var(--clave4-letter);
    left: 410px;
    top:374px;
    z-index: 100;
  }

  .elipseclave5 {
    background-color: var(--clave5-rs);
    left: 258px;
    top: 317px;
    z-index: 100;
  }
  
  .valorclave5 {
    color: var(--clave5-letter);
    left: 311px;
    top: 434px;
    z-index: 100;
  }

  .elipseclave6 {
    background-color: var(--clave6-rs);
    left: 207px;
    top: 317px;
    z-index: 100;
  }
  
  .valorclave6 {
    color: var(--clave6-letter);
    left: 178px;
    top: 440px;
    z-index: 100;
  }

  .elipseclave7 {
    background-color: var(--clave7-rs);
    left: 160px;
    top: 282px;
    z-index: 100;
  }
  
  .valorclave7 {
    color: var(--clave7-letter);
    left: 61px;
    top:374px;
    z-index: 100;
  }

  .elipseclave8 {
    background-color: var(--clave8-rs);
    left: 146px;
    top: 228px;
    z-index: 100;
  }
  
  .valorclave8 {
    color: var(--clave8-letter);
    left: 7px;
    top: 228px;
    z-index: 100;
  }
  .elipseclave9 {
    background-color: var(--clave9-rs);
    left: 168px;
    top: 177px;
    z-index: 100;
  }
  
  .valorclave9 {
    color: var(--clave9-letter);
    left: 47px;
    top: 95px;
    z-index: 100;
  }
  .elipseclave10 {
    background-color: var(--clave10-rs);
    left: 213px;
    top: 156px;
    z-index: 100;
  }
  
  .valorclave10 {
    color: var(--clave10-letter);
    left: 159px;
    top: 20px;
    z-index: 100;
  }



.graficoCenter {
  width: 500px;
  height: 500px;
}

/* @media only screen and (max-width: 1370px) {
  .graficoCenter {
    margin-top: 50px;
    margin-left: 90px;
  }
}
@media only screen and (max-width: 1024px) {
  .graficoCenter {
    margin-top: 50px;
    margin-left: 20px;
    
  }
} */
.mediaGrafico{
  position: absolute;
  left: 12px;
  width: 155px;
  top: 55px;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #383b41;
}


.medias {
  position: absolute;
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.medias svg {
  position: absolute !important;
  transform: rotate(2deg);
}

.mediaClave {
  position: absolute !important;
  z-index: 100000;
}
.medias svg:nth-child(2)  {
  transform: rotate(37deg);
}
.medias svg:nth-child(3)  {
  transform: rotate(72deg);
}
.medias svg:nth-child(4)  {
  transform: rotate(108deg);
}
.medias svg:nth-child(5)  {
  transform: rotate(143deg);
}
.medias svg:nth-child(6)  {
  transform: rotate(179deg);
}
.medias svg:nth-child(7)  {
  transform: rotate(217deg);
}
.medias svg:nth-child(8)  {
  transform: rotate(253deg);
}
.medias svg:nth-child(9)  {
  transform: rotate(290deg);
}
.medias svg:nth-child(10)  {
  transform: rotate(326deg);
}


.valorm {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  position: absolute;
  color: black;
}



.valorclavem1 {
left: 348px;
top: 20px;
z-index: 100;
}


.valorclavem2 {
  left: 449px;
  top: 95px;
  z-index: 100;
}



.valorclavem3 {
  left: 499px;
  top: 228px;
  z-index: 100;
}




.valorclavem4 {
  left: 446px;
  top: 374px;
  z-index: 100;
}


.valorclavem5 {
  left: 349px;
  top: 434px;
  z-index: 100;
}



.valorclavem6 {
 
  left: 141px;
  top: 440px;
  z-index: 100;
}


.valorclavem7 {
  left: 25px;
  top:374px;
  z-index: 100;
}


.valorclavem8 {
  left: -26px;
  top: 228px;
  z-index: 100;
}

.valorclavem9 {
  left: 12px;
  top: 95px;
  z-index: 100;
}


.valorclavem10 {
  left: 120px;
  top: 20px;
  z-index: 100;
}






.sliceWrapperClaveMedia1 {
  position: absolute;
/*  
  border-radius: 50%;
  padding: 5px; */
}

.roundcl1 {
  transform: rotate(272deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 82 999;
}


.roundcl2 {
  transform: rotate(307deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 82 999;
}


.roundcl3 {
  transform: rotate(342deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 82 999;
}

.roundcl4 {
  transform: rotate(378deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 82 999;
}

.roundcl5 {
  transform: rotate(413deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 82 999;
}

.roundcl6 {
  transform: rotate(449deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 73 999;
}

.roundcl7 {
  transform: rotate(488deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 94 999;
}

.roundcl8 {
  transform: rotate(523deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 82 999;
}

.roundcl9 {
  transform: rotate(560deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 72 999;
}

.roundcl10 {
  transform: rotate(237deg);
  
  /* SVG */
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 87 999;
}


.slice1 {
  position:absolute;
  width:200px;
  height:200px;
  clip:rect(0px, 100px, 200px, 0px);
  -moz-border-radius:100px;
  -webkit-border-radius:100px;
  border-radius:100px;
  background-color:#f7e5e1;
  border-color:#f7e5e1;
  -moz-transform:rotate(0);
  -webkit-transform:rotate(0);
  -o-transform:rotate(0);
  transform:rotate(0);
}




.sliceWrapperClaveMediaSal {
 
  position: absolute;
 
  border-radius: 50%;
  padding: 5px;
  list-style: none;
  overflow: hidden;
  border: 2px solid #000000;
}

.sliceWrapperClaveMediaSal:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 0px;
  border: 2px solid #000000;
}

.slicecltranps {

  background: transparent !important;
}