/* ------------------------------ */
/* Custom CookieYes cookie banner
/* ------------------------------ */
.cky-consent-container:not(.cky-hide)::before {
	content: "";
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);
	z-index: -1;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}

.cky-consent-container:not(.cky-hide) {
	position: fixed;
	width: 100%;
	box-sizing: border-box;
	z-index: 999999;
	bottom: unset !important;
	left: unset !important;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.cky-consent-container .cky-consent-bar {
	width: 600px;
	border-radius: 6px;
	box-shadow: 0 0 10px #555555 !important;
}

.cky-notice-group {
	flex-direction: column;
	
}

.cky-notice-btn-wrapper {
	margin-top: 20px;
	margin-left: 0 !important;
}

.cky-consent-container.cky-classic-bottom.cky-consent-bar-expand {
	animation: none !important;
}
.cky-consent-container.cky-classic-bottom.cky-consent-bar-expand .cky-preference-wrapper {
	position: absolute;
}

@media (max-width: 782px) {
	.cky-consent-container .cky-consent-bar {
		width: 95%;
	}
}

body:has(> div.cky-consent-container:not(.cky-hide)) {
  overflow: hidden !important;
}
